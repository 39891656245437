import React from "react";

const MainBody = React.forwardRef(({gradient, icons}, ref) => {
    return (
      <div>
          <h1 ref={ref} className="display-1">Maksim Terentev</h1>
            {icons.map((icon, index) => (
              <a
                key={`social-icon-${index}`}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
              </a>
            ))}
      </div>
    );
  }
);

export default MainBody;
