import React from 'react';
import "./_resume.scss";

const Resume = ({}) => {
  return (
    <section id="resume" className="resume">
      <div className="container">

        <div className="section-title">
          <h2>Resume</h2>
          <p>
            Technical summary:
          </p>
          <ul>
            <li>
              Fullstack Java Engineer with 10+ years of software development experience with
              knowledge of front-end and server-side technologies, such as J2EE,
              Spring Framework, GWT, Grails, Guice, Guava, JSF, Archaius, etc
            </li>
            <li>
              Fullstack Javascript Developer with 5+ years of development experience with
              knowledge of node.js, angular.js, reactjs, express.js
            </li>
            <li>
              Experience in building large-scale web applications, SOAP/REST services and
              messaging middlewares (RabbitMQ)
            </li>
            <li>
              Deep understanding of object oriented analysis, methodology and design
            </li>
            <li>
              Hands-on experience in design, development and deployment using Agile methodology
            </li>
            <li>
              Knowledge of RDBMS: PostgreSQL, Oracle, MySQL
            </li>
            <li>
              Knowledge of MongoDB, Redis
            </li>
          </ul>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <h3 className="resume-title">Professional Experience</h3>

            <div className="resume-item">
              <h4>Software Engineer</h4>
              <h5>2017 - Present</h5>
              <p><em>Pandora, Oakland, CA</em></p>
              <ul>
                <li>
                  Fullstack development for Pandora applications and services on TVs,
                  smart devices, and smart speakers
                </li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Lead Software Engineer</h4>
              <h5>2013 - 2017</h5>
              <p><em>Google via EPAM Systems, Mountain View, CA</em></p>
              <ul>
                <li>
                  Worked with Google engineers in ‘Corporate Engineering’ teams
                </li>
                <li>
                  Implemented and supported internal apps using Google technologies and
                  environment.
                </li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Software Engineer</h4>
              <h5>2013 - 2013</h5>
              <p><em>WebZavod, Samara, Russia</em></p>
              <ul>
                <li>
                  Created citywide social network
                </li>
                <li>
                  Implemented eGovernment tool to process citizen's request
                </li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Software Engineer</h4>
              <h5>2011 - 2013</h5>
              <p><em>New Standards, Sunnyvale, CA</em></p>
              <ul>
                <li>
                  Migrate existing Camera Awesome iOS app to Android
                </li>
                <li>
                  Supporting two Oracle iStores.
                </li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Software Engineer</h4>
              <h5>2011 - 2012</h5>
              <p><em>EPAM Systems, Newtown, PA</em></p>
              <ul>
                <li>
                  Designing and implementing PKI infrastructure, internal document tool,
                  document eSigning and verification subsystem for Russian SberBank and
                  Home Credit Bank
                </li>
                <li>
                  Created the first distributed dev team with 12 hours TZ difference for the 1st
                  tier company
                </li>
                <li>
                  Redesigned whole web-application
                </li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>University Teaching Assistant</h4>
              <h5>2009 - 2012</h5>
              <p><em>Samara State University, Samara, Russia</em></p>
              <ul>
                <li>
                  Lead the Information Security, Cryptographic Protocols, and Software development
                  courses.
                </li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Software Engineer</h4>
              <h5>2010 - 2011</h5>
              <p><em>Haulmont, London, England</em></p>
              <ul>
                <li>
                  Took a part in ShiftIntoSport - the online fitness subscription marketplace
                </li>
                <li>
                  Implemented UI engine
                </li>
              </ul>
            </div>

            <div className="resume-item">
              <h4>Software Engineer</h4>
              <h5>2006 - 2009</h5>
              <p><em>EPAM Systems, Newtown, PA</em></p>
              <ul>
                <li>
                  Designing and implementing C++\WinAPI embedded application for
                  Transcend
                </li>
                <li>
                  Developing JEE Portal's (JSR-168, IBM WebSphere Portal Server 6, Oracle);
                  Designed and implementing components of Kazakhstan eGovernment;
                  Designed and implemented eBank co-working subsystem prototype
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-6">
            <h3 className="resume-title">Summary</h3>

            <div className="pb-0 resume-item">
              <h4>Maksim Terentev</h4>
              <p><em>
                Fullstack Java/Javascript engineer
              </em></p>
              <ul>
                <li>San francisco Bay Area, CA</li>
              </ul>
            </div>

            <h3 className="resume-title">Education</h3>
            <div className="resume-item">
              <h4>Master of Math</h4>
              <h5>2003 - 2009</h5>
              <p><em>Samara State University, Samara, Russia</em></p>
              <p>
                Math, Computer and Information Security/Information Assurance.
                Graduated with honors
              </p>
            </div>
            <div className="resume-item">
              <h4>Bachelor of Language Interpretation &amp; Translation</h4>
              <h5>2004 - 2007</h5>
              <p><em>Samara State University, Samara, Russia</em></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
