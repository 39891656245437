import React from 'react';
import profile from "./profile-img2-min.jpg";
import "./_about.scss";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container">

        <div className="section-title">
          <h2>About</h2>
          <p>
            Software Engineer since 2006. With expertize on eGovernment, Banks, Startups,
            Google, commercial, advertise, and media systems.
          </p>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <img src={profile} className="img-fluid" alt="Profile"/>
          </div>
          <div className="col-lg-8 pt-4 pt-lg-0 content">
            <h3>Software Engineer.</h3>
            <p className="fst-italic">
              Fullstack engineer with deep knowledge of Java, Javascript (Typescript).
            </p>
            <div className="row">
              <div className="col-lg-6">
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"/>
                    <strong>Website:</strong>
                    <span>terentyev.me</span>
                  </li>
                  <li>
                    <i className="bi bi-chevron-right"/>
                    <strong>Location:</strong>
                    <span>San Francisco Bay Area, CA</span>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6">
                <ul>
                  <li>
                    <i className="bi bi-chevron-right"/>
                    <strong>Degree:</strong>
                    <span>Master</span></li>
                  <li>
                    <i className="bi bi-chevron-right"/>
                    <strong>Telegram:</strong>
                    <span>
                      <a href="https://t.me/dupov" target="_blank">@dupov</a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <p>Currently work somewhere.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
