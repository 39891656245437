import React from "react";
import Hero from "../../components/hero/Hero";
import About from "../../components/about/About";
import Resume from "../../components/resume/Resume";
import Portfolio from "../../components/portfolio/Portfolio";

const Main = () => {
  return (
    <>
      <Hero/>
      <main id="main">
        <About/>
        <Resume/>
        {/*<Portfolio/>*/}
      </main>
    </>
  );
};

export default Main;
