import React from 'react';
import './_hero.scss';
import SocialLink from "./SocialLink";

const name = "Maksim Terentev";
const socialData = [
  {link: "https://t.me/dupov", social: "telegram"},
  {link: "https://www.linkedin.com/in/maksimterentyev", social: "linkedin"},
];

const Hero = () => {
  return (
    <section id="hero" className="d-flex flex-column justify-content-center">
      <div className="container">
        <h1>{name}</h1>
        <div className="social-links">
          {socialData.map(s => (
            <SocialLink link={s.link} social={s.social} key={s.social}/>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Hero;
