import React, {useEffect, useState} from "react";
import "./_backToTop.scss";
import useScrollPosition from "../../hooks/useScrollPosition";

const BackToTop = () => {
  const [isTop, setIsTop] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);

  useScrollPosition(({prevPos, currPos}) => {
      setScrollPosition(currPos.y);
    }, [0]
  );

  useEffect(() => {
    setIsTop(scrollPosition > -100);
  }, [scrollPosition]);


  return (
    <a
      href="#"
      className={`back-to-top ${isTop ? "" : "active"} d-flex align-items-center justify-content-center`}>
      <i className="bi bi-arrow-up-short"/>
    </a>
  );
};

export default BackToTop;
