import React from 'react';
import "./_preloader.scss";

const Preloader = () => {
  return (
    <div id="preloader"/>
  );
};

export default Preloader;
