import React from 'react';
import "./_footer.scss";
import SocialLink from "../hero/SocialLink";

const name = "Maksim Terentev";
const socialData = [
  {link: "https://t.me/dupov", social: "telegram"},
  {link: "https://www.linkedin.com/in/maksimterentyev", social: "linkedin"},
];

const Footer = () => {
  return (
    <footer id="footer">
      <div className="container">
        <h3>{name}</h3>
        <p>Тут идет какая-то умная мысль, но мне ее лень придумывать.</p>
        <div className="social-links">
          {socialData.map(s => (<
            SocialLink link={s.link} social={s.social} key={s.social}/>
          ))}
        </div>
        <div className="credits">
          {/*<!-- All the links in the footer should remain intact. -->
            <!-- You can delete the links only if you purchased the pro version. -->
            <!-- Licensing information: [license-url] -->
            <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/free-html-bootstrap-template-my-resume/ -->*/}
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
