import React, {useEffect, useState} from "react";
import {BrowserRouter, Route} from "react-router-dom";
import ReactGA from 'react-ga';
import MainBody from "./components/home/MainBody";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Preloader from "./components/preloader/Preloader";
import BackToTop from "./components/backToTop/BackToTop";
import Main from "./pages/main/Main";
import {Redirect, Switch} from "react-router";
import Error from "./pages/error/Error";
import WillMatch from "./pages/otherPage/Error";
import {createBrowserHistory, createHashHistory} from "history";

const Home = React.forwardRef((props, ref) => {
  return (
    <>
      <MainBody
        ref={ref}
      />
      {/*{about.show && <AboutMe*/}
      {/*  link="4name"*/}
      {/*  resume="https://docs.google.com/document/d/13_PWdhThMr6roxb-UFiJj4YAFOj8e_bv3Vx9UHQdyBQ/edit?usp=sharing"*/}
      {/*/>}*/}
    </>
  );
});

// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// });
const history = createBrowserHistory();
// Initialize google analytics page view tracking
history.listen((location, action) => {
  // console.log(111, action, location.pathname, location.state);
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const App = () => {
  const [preloader, setPreloader] = useState(true);

  useEffect(() => {
    setPreloader(false);
  }, []);

  return (
    <BrowserRouter
      basename={process.env.PUBLIC_URL + "/"}
      history={history}
    >
      <Header/>
      <Switch>
        <Route exact path="/">
          <Main/>
        </Route>
        <Route path="/old-match">
          <Redirect to="/will-match"/>
        </Route>
        <Route path="/will-match">
          <WillMatch/>
        </Route>
        <Route path="*">
          <Error/>
        </Route>
      </Switch>
      {/* {false && <Route path="/blog" exact component={() => <Blog/>} />}
      {false && <Route path="/blog/:id" component={BlogPost} />} */}

      <BackToTop/>
      <Footer/>
      {preloader ? <Preloader/> : null}
    </BrowserRouter>
  );
};

export default App;
