import React, {useState} from "react";
import './_header.scss';
import './_navmenu.scss';
// import {useScrollPosition} from "../../hooks/useScrollPosition";
import {Link} from "react-router-dom";
import {HashLink, NavHashLink} from 'react-router-hash-link';


const Header = React.forwardRef((props, ref) => {
  const navbarMenuRef = React.useRef();
  const [isTop, setIsTop] = useState(true);
  const [isActive, setActive] = useState(false);

  const mobileNavToggle = () => {
    setActive(!isActive);
  };

  const menuClick = () => {
    setActive(false);
  }

  // const [scrollPosition, setScrollPosition] = useState(0);
  // const navbarDimensions = useResizeObserver(navbarMenuRef);
  // const navBottom = navbarDimensions ? navbarDimensions.bottom : 0;
  // useScrollPosition(
  //   ({prevPos, currPos}) => {
  //     if (!navbarDimensions) return;
  //     if (currPos.y + ref.current.offsetTop - navbarDimensions.bottom > 5) {
  //       setIsTop(true);
  //     } else {
  //       setIsTop(false);
  //     }
  //     setScrollPosition(currPos.y);
  //   },
  //   [navBottom]
  // );
  //
  // React.useEffect(() => {
  //   if (!navbarDimensions) return;
  //   if (navBottom - scrollPosition >= ref.current.offsetTop) {
  //     setIsTop(false);
  //   } else {
  //     setIsTop(true);
  //   }
  // }, [navBottom, navbarDimensions, ref, scrollPosition]);

  return (
    <div className={isActive ? 'mobile-nav-active' : ''}>
      <i
        className={`bi ${isActive ? 'bi-x' : 'bi-list'} mobile-nav-toggle d-xl-none`}
        onClick={mobileNavToggle}
      />
      <header
        id="header"
        className={`d-flex flex-column justify-content-center`}
      >
        <nav
          id="navbar"
          className="navbar nav-menu"
          ref={navbarMenuRef}
        >
          <ul>
            <li>
              <NavHashLink
                to={{pathname: "/", hash: "#hero"}}
                smooth
                activeClassName="active"
                className="nav-link scrollto"
                onClick={menuClick}
              >
                <i className="bi bi-house"/>
                <span>Home</span>
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                to={{pathname: "/", hash: "#about"}}
                smooth
                activeClassName="active"
                className="nav-link scrollto"
                onClick={menuClick}
              >
                <i className="bi bi-person"/>
                <span>About</span>
              </NavHashLink>
            </li>
            <li>
              <NavHashLink
                to={{pathname: "/", hash: "#resume"}}
                smooth
                activeClassName="active"
                className="nav-link scrollto"
                onClick={menuClick}
              >
                <i className="bi bi-file-earmark"/>
                <span>Resume</span>
              </NavHashLink>
            </li>
            {/*<li>*/}
            {/*  <a href="#portfolio" className="nav-link scrollto">*/}
            {/*    <i className="bi bi-card-text"/>*/}
            {/*    <span>Portfolio</span>*/}
            {/*  </a>*/}
            {/*</li>*/}
            {/*<li>*/}
            {/*  <Link to="/will-match" className="nav-link scrollto">*/}
            {/*    <i className="bi bi-stars"/>*/}
            {/*    <span>Willmatch</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
          </ul>
        </nav>
      </header>
    </div>
  );
});

export default Header;
