import React from "react";

const SocialLink = ({link, social}) => {
  return (
    <a href={link}
       className={social}
       target="_blank"
       rel="noopener noreferrer">
      <i className={`bi bi-${social}`}/>
    </a>
  );
}

export default SocialLink;
