import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const WillMatch = () => {
  const a = () => {
    // console.log(222)
    history.push('/home', { some: 'state' });
  }
  return (
    <div onClick={a}>WillMatch</div>
  );
}

export default WillMatch;
