import React from 'react'
import PWAInstallerPrompt from 'react-pwa-installer-prompt';

const PwaPromt = () => {
  return (
    <PWAInstallerPrompt
      render={({onClick}) => (
        <button type="button" onClick={onClick}>
          Install
        </button>
      )}
      callback={(data) => console.log(data)}
    />
  );
}

export default PwaPromt;
